
import { Prop, Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import PriceFilter from '@/modules/common/filters/price.filter';
import type DiLiteAllChannelsService from '@/modules/di-lite/di-lite-all-channels.service';
import type { ProviderData } from '@/modules/di-lite/interfaces/provider-data.interface';
import Currency from '@/modules/common/components/currency.vue';
import DayBetterIndicators from '@/modules/rates/components/table/day-better-indicators.vue';
import DayIndicatorTooltip from '@/modules/rates/components/table/day-indicator-tooltip.vue';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        Currency,
        DayBetterIndicators,
        DayIndicatorTooltip,
    },
    filters: { PriceFilter },
})
export default class AllChannelCell extends Vue {
    @inject(KEY.DiLiteAllChannelsService) allChannelsService!: DiLiteAllChannelsService;

    @Prop({ required: false, default: false })
    private isBrand!: boolean;

    @Prop({
        type: [],
        required: true,
    })
    public data!: ProviderData;

    @Prop({ type: String })
    private day!: string;

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get netTotalCalculationTitle() {
        if (this.data.isNetCalc) {
            return this.data.isNetCalc ? this.$tc('rates.priceIsNetCalculated') : '';
        }

        if (this.data.isTotalCalc) {
            return this.data.isTotalCalc ? this.$tc('rates.priceIsTotalCalculated') : '';
        }

        return '';
    }

    get priceIndicator() {
        return {
            losRestriction: this.data.losRestriction,
        };
    }

    get referencePrice() {
        return this.allChannelsService
            .getReferencePrice(this.day);
    }

    get priceColorClass() {
        if (this.isBrand) return {};
        const { price } = this.data;
        const { referencePrice } = this;

        if (!referencePrice) return {};
        if (!price) return {};

        const lowReferenceError = referencePrice * (1 - 0.015);
        const highReferenceError = referencePrice * (1 + 0.015);

        const isEqualReferencePrice = price > lowReferenceError && price < highReferenceError;

        return {
            'all-channels-cell--green': isEqualReferencePrice,
            'all-channels-cell--yellow': referencePrice < price && !isEqualReferencePrice,
            'all-channels-cell--red': referencePrice > price && !isEqualReferencePrice,
        };
    }
}
