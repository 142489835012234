

import { inject } from '@/inversify';
import { Vue, Component } from 'vue-property-decorator';
import { Locale } from 'vue-i18n';

import type UserService from '@/modules/user/user.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import HorizontalArrows from '@/modules/common/components/ui-kit/horizontal-arrows.vue';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type Month from '@/modules/common/types/month.type';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import CurrencySwitcherTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import DiLiteHintTooltip from './di-lite-hint-tooltip.vue';

@Component({
    components: {
        MonthPicker,
        CurrencySwitcher,
        HorizontalArrows,
        CurrencySwitcherTooltip,
        DiLiteHintTooltip,
    },
})
export default class DiLiteHeader extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    changeMonth(diff: number) {
        const date = new Date(this.date);
        date.setMonth(date.getMonth() + diff);
        this.date = date;
    }

    get date(): Date {
        const { month, year } = this.documentFiltersService.storeState.settings;
        return new Date(year, month, 1);
    }

    set date(date: Date) {
        this.documentFiltersService.saveMonth(date.getMonth() as Month);
        this.documentFiltersService.saveYear(date.getFullYear());
    }

    get locale(): Locale {
        return this.$i18n.locale;
    }

    get hotelName() {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.hotelNames[currentHotelId];
    }

    get isHotelUser() {
        return !this.userService.isChainOrClusterUser;
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}
