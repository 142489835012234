

import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type ConfigService from '@/modules/config/config.service';
import type UserService from '@/modules/user/user.service';

@Component
export default class DIUpgradeCard extends Vue {
    @inject(KEY.ConfigService) private configService!: ConfigService;
    @inject(KEY.UserService) private userService!: UserService;
}
